export default {
  data() {
    return {
      texts: "",
      screenWidth: 0,
      bLoading: false,
      arrUserDetail:[],
      apiDeleteItem:"",
      apiChangeStatus:""
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();

  },
  beforeMount() {
    this.texts = FILE.detailuserTexts[this.selectLanguage];
  },
  methods: {
    close() {
      this.$store.commit("setdialogDetailsGlobalUser", 
      {active: false,
      arr : []});
    },
    deleteItem(){
      DB.delete(this.apiDeleteItem , {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
        }
      })
        .then((response) => {
          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
                    this.mixError(error.response.data.message,error.response.status);
        });
    },
    changeEstatusItem(){
      this.close();
      this.$store.commit("setGlobalDialog", {
        active: true,
        title: this.texts.user.vendor.changeStatusTexts.title,

        descipcion: (this.arrUserDetail.bPlatformAccess) ? 
        this.texts.user.vendor.changeStatusTexts.activeToInactive.description
        :
        this.texts.user.vendor.changeStatusTexts.inactiveToActive.description ,

        note:(this.arrUserDetail.bPlatformAccess)?
         this.texts.user.vendor.changeStatusTexts.activeToInactive.note
         :
         this.texts.user.vendor.changeStatusTexts.inactiveToActive.note
         ,

        chkLabel: (this.arrUserDetail.bPlatformAccess)?
         this.texts.user.vendor.changeStatusTexts.activeToInactive.chkLabel
         :
         this.texts.user.vendor.changeStatusTexts.inactiveToActive.chkLabel,
        // api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$route.params.id}/employees/${this.arrUserDetail.sVendorId}`,
        api: this.apiChangeStatus,
        params:{ bPlatformAccess:  !this.arrUserDetail.bPlatformAccess },
        action:1,// "1" is change to state to user and "2 is change to verified to provider or customer"
        return:false,
        dobleConfirm:true
      });
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 50;
      } else {
        this.screenWidth = 100;
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogGlobalDetailsUser() {
      return this.$store.state.dialogGlobalDetailsUser;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.detailuserTexts[this.selectLanguage];
      }
    },
    dialogGlobalDetailsUser: function () {
      if (this.dialogGlobalDetailsUser) {
        this.texts = FILE.detailuserTexts[this.selectLanguage];
        // this.getDetailUser(this.$store.state.arrDetailGlobalUser);
        this.arrUserDetail = this.$store.state.arrDetailGlobalUser
        this.apiChangeStatus = this.$store.state.apiGlobalUserChangeStatus
        this.apiDeleteItem = this.$store.state.apiGlobalUser
      }
    },
  },
};